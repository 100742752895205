<template>
  <base-material-card
    color="primary"
    icon="mdi-pencil"
    :title="`Informações Básicas :: ${nomeRelatorioModelo}`"
    inline
    class="px-5 py-3"
  >
    <v-form>
      <v-row>
        <v-col>
          <v-alert
            v-if="descricao"
            dense
            dismissible
            outlined
            type="info"
            class="mb-0 py-0 px-1 icon-right-alert-ib"
          >
            {{ descricao }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
        class="ml-0"
      >
        <v-col class="pt-2">
          <validation-provider
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              outlined
              :error-messages="errors"
              v-model="nome"
            >
              <template v-slot:label>
                Nome do Relatório <obrigatorio />
              </template>
            </v-text-field>
          </validation-provider>
        </v-col>
      </v-row>
    </v-form>
  </base-material-card>
</template>

<script>
export default {
  components: {
    Obrigatorio: () => import('@/components/general/GeneralMarkObrigatorio')
  },

  props: {
    nomeRelatorioModelo: {
      type: String,
      default: ''
    },
    descricao: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    nome: ''
  }),

  mounted() {
    this.nome = this.nomeRelatorioModelo;
  },

  methods: {
    exportCampos() {
      return this.nome;
    }
  }
};
</script>

<style>
.icon-right-alert-ib button {
  margin-right: 3px !important;
}
</style>
